.excelentStatus {
  color: #33cc95;
}

.fairStatus {
  color: #cc9533;
}

.poorStatus {
  color: #cc336a;
}
